// 網路設備總覽 -> 前 10 筆 Switch Port 流量
const portFlow = {
  //  一般埠
  normal: {
    // x 軸
    xAxis: [
      'HP 1810-24G Switch - Fa0/23',
      'GO-SW-24GE - Fa0/23',
      'C6513-E - Fa0/14',
      'C6513-E - Fa0/15',
      'HP 1810-24G Switch - Fa0/23',
      '3CBLSG48 - Fa0/23',
      '3CGSU08 - Fa0/24',
      'C6513-E - Port-channel2',
      'C6513-E - Port-channel2',
      'C6513-E - Port-channel2'
    ],
    // y 軸
    yAxis: {
      // 上傳
      update: [100, 90, 70, 60, 40, 18, 10, 5, 2, 2],
      // 下載
      download: [30, 100, 90, 50, 35, 10, 20, 30, 25, 25]
    }
  },
  // 串接埠
  serial: {
    // x 軸
    xAxis: ['C6513-E - Port-channel2', 'C6513-E - Fa0/2'],
    // y 軸
    yAxis: {
      // 上傳
      update: [100, 10],
      // 下載
      download: [100, 95]
    }
  },
  // 對外埠
  foriegn: {
    xAxis: ['C6513-E - Fa0/1', 'C3850 for 3F - Gi1/0/28', '3CBLSG48 - Gi0/2'],
    yAxis: {
      update: [100, 20, 10],
      download: [100, 90, 30]
    }
  }
}

export { portFlow }
