// Switch 資訊，TreeView 假資料

const switchInfoTree = [
  {
    id: 0,
    name: 'Dlink',
    isParent: true,
    icons: [],
    expanded: true,
    childrens: [
      {
        id: 1,
        name: 'C3815-24T for 3F (192.168.111.2)',
        isParent: false,
        icons: ['tree-i-switch']
      },
      {
        id: 2,
        name: 'C3223495 (192.168.111.22)',
        isParent: true,
        icons: ['tree-i-switch'],
        expanded: true,
        childrens: [
          {
            id: 3,
            name: 'Gi1/0/22 (Port-channel2)',
            isParent: false,
            icons: ['tree-i-port-0', 'tree-i-serial']
          },
          {
            id: 4,
            name: 'Gi1/0/21 (Port-channel2)',
            isParent: false,
            icons: ['tree-i-port-2']
          },
          {
            id: 5,
            name: 'Gi1/0/21 (Port-channel2)',
            isParent: false,
            icons: ['tree-i-port-0']
          },
          {
            id: 6,
            name: 'Gi1/0/21 (Port-channel2)',
            isParent: false,
            icons: ['tree-i-port-0']
          },
          {
            id: 7,
            name: 'Gi1/0/21 (Port-channel2)',
            isParent: false,
            icons: ['tree-i-port-1', 'tree-i-people']
          },
          {
            id: 8,
            name: 'Gi1/0/21 (Port-channel2)',
            isParent: false,
            icons: ['tree-i-port-0', 'tree-i-foriegn']
          },
          {
            id: 9,
            name: 'Gi1/0/21 (Port-channel2)',
            isParent: false,
            icons: ['tree-i-port-3']
          }
        ]
      }
    ]
  },
  {
    id: 10,
    name: 'Unknow',
    isParent: true,
    icons: [],
    expanded: false,
    childrens: [
      {
        id: 1,
        name: 'C3815-24T for 3F (192.168.111.2)',
        isParent: false,
        icons: ['tree-i-switch']
      },
      {
        id: 2,
        name: 'C3223495 (192.168.111.22)',
        isParent: true,
        icons: ['tree-i-switch'],
        expanded: true,
        childrens: [
          {
            id: 3,
            name: 'Gi1/0/22 (Port-channel2)',
            isParent: false,
            icons: ['tree-i-port-0', 'tree-i-serial']
          },
          {
            id: 4,
            name: 'Gi1/0/21 (Port-channel2)',
            isParent: false,
            icons: ['tree-i-port-2']
          },
          {
            id: 5,
            name: 'Gi1/0/21 (Port-channel2)',
            isParent: false,
            icons: ['tree-i-port-0']
          },
          {
            id: 6,
            name: 'Gi1/0/21 (Port-channel2)',
            isParent: false,
            icons: ['tree-i-port-0']
          },
          {
            id: 7,
            name: 'Gi1/0/21 (Port-channel2)',
            isParent: false,
            icons: ['tree-i-port-1', 'tree-i-people']
          },
          {
            id: 8,
            name: 'Gi1/0/21 (Port-channel2)',
            isParent: false,
            icons: ['tree-i-port-0', 'tree-i-foriegn']
          },
          {
            id: 9,
            name: 'Gi1/0/21 (Port-channel2)',
            isParent: false,
            icons: ['tree-i-port-3']
          }
        ]
      }
    ]
  }
]

const schema = {
  model: {
    id: 'id',
    hasChildren: 'isParent',
    children: 'childrens',
    schema: {
      model: {
        id: 'id',
        hasChildren: 'isParent'
      }
    }
  }
}

export { switchInfoTree, schema }
