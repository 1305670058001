
import { defineComponent, onMounted, reactive, ref, watch } from 'vue'
import useEChartsInPortFlow from '@/composable/useEChartsInPortFlow'
import BaseRadio from '@/components/Radio/BaseRadio.vue'
import BaseButtonGroup from '@/components/Button/BaseButtonGroup.vue'
import BaseButtonGroupBtn from '@/components/Button/BaseButtonGroupBtn.vue'
import elementResizeDetectorMaker from 'element-resize-detector'

export default defineComponent({
  components: {
    BaseRadio,
    BaseButtonGroup,
    BaseButtonGroupBtn
  },
  props: {
    title: String
  },
  setup(_, ctx) {
    // 圖表狀態
    // 綁定：埠種類，流量方向，流量單位
    const chartState = reactive({
      portType: 'normal', // 一般埠: normal, 串接埠: serial, 對外埠: foriegn
      dir: 'update', // 上傳量: update, 下載量: download
      unit: 'percentage' // 百分比: percentage, 絕對值: default
    })

    // 繪圖相關
    let chartInstanse
    const { chartElement, draw, optionWithState } = useEChartsInPortFlow(
      chartState
    )

    // Resize 相關
    const erd = elementResizeDetectorMaker()
    const resizeListener = () => chartInstanse.resize()

    onMounted(() => {
      // 初始繪圖
      chartInstanse = draw()
      chartInstanse.on('click', params => {
        // FIXME: 圖表點擊處理
        console.log(params.name)
        ctx.emit('toggleSwitchInfoDialog', params)
      })
      // onMount 3000ms 後，開始監聽 chartElement 寬度變化
      setTimeout(() => {
        erd.listenTo(chartElement.value, resizeListener)
      }, 3000)
    })

    watch(chartState, () => {
      // chartState 已更新，重新繪圖
      chartInstanse.setOption(optionWithState())
    })

    // 流量圖 Radio 選項
    const radioGroup = ref([
      { id: 0, name: '一般埠', value: 'normal', color: '#355DFF' },
      { id: 1, name: '串接埠', value: 'serial', color: '#3DCB97' },
      { id: 2, name: '對外埠', value: 'foriegn', color: '#FCCB36' }
    ])

    // Button Group 相關
    const btnGroupDir = ref([
      { id: 0, name: '上傳量', value: 'update', active: true },
      { id: 1, name: '下載量', value: 'download', active: false }
    ])
    const toggleBtnGroupDir = id => {
      btnGroupDir.value.forEach(btn => {
        if (btn.id == id) {
          btn.active = true
          chartState.dir = btn.value
        } else {
          btn.active = false
        }
      })
    }

    const btnGroupUnit = ref([
      { id: 0, name: '百分比', value: 'percentage', active: true },
      { id: 1, name: '絕對值', value: 'default', active: false }
    ])
    const toggleBtnGroupUnit = id => {
      btnGroupUnit.value.forEach(btn => {
        if (btn.id == id) {
          btn.active = true
          chartState.unit = btn.value
        } else {
          btn.active = false
        }
      })
    }

    return {
      chartElement,
      chartState,
      radioGroup,
      btnGroupDir,
      toggleBtnGroupDir,
      btnGroupUnit,
      toggleBtnGroupUnit
    }
  }
})
