import { ref } from 'vue'
import * as echarts from 'echarts/dist/echarts.js'
// 假資料
import { portFlow as data } from '../mock/data/portFlow'

const useEChartsInPortFlow = chartState => {
  const chartElement = ref(null)

  // 根據 state 返回 option
  const optionWithState = () => {
    const barLabel = {
      show: true,
      position: [0, -32],
      formatter: function (val) {
        return val.value >= 90 ? '{icon|} {text|前往查看}' : ''
      },
      rich: {
        // 富文本標籤
        text: {
          color: '#E85153',
          fontSize: 14
        },
        icon: {
          backgroundColor: {
            // 使用 require 解析圖片 url
            image: require('../assets/images/icons-fixed-color/danger.svg')
          },
          height: 20
        }
      }
    }

    const mapYData = dataArray => {
      // 從 [100, ...] -> [{
      //   value: 100,
      //   itemStyle: { color: '#A83E41' },
      //   label: barLabel
      // }]
      return dataArray.map(dataValue => {
        return {
          value: dataValue,
          itemStyle:
            // 大於 80% 顯示紅色
            dataValue >= 80 ? { color: '#A83E41' } : { color: '#2F9471' },
          label: barLabel,
          // hover 變亮
          emphasis: {
            itemStyle:
              dataValue >= 80 ? { color: '#F27778' } : { color: '#63D5AB' }
          }
        }
      })
    }
    const option = {
      grid: {
        top: '10%',
        right: '8.3%',
        bottom: '20%',
        left: '8.3%'
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'none'
        }
      },
      xAxis: {
        zlevel: 1,
        type: 'category',
        data: data[chartState.portType].xAxis, // 取得 chartState 對應 x 軸資料 (Array)
        axisTick: {
          show: false, // 座標軸刻度,
          interval: 0
        },
        axisLabel: {
          interval: 0, // 強制每個標籤都顯示，因為'默認會採用標籤不重疊的方式間格顯示標籤'
          show: true,
          rotate: 0,
          inside: false,
          align: 'center',
          color: '#9B9FA8',
          fontSize: 14,
          width: 80,
          height: 100,
          overflow: 'breakAll',
          formatter: function (value) {
            const line1 = value.slice(0, 7) // 0-6 位
            const line2 = value.slice(7, 14) // 7-13 位
            return `${line1}\n${line2}...` // 換行、加...
          }
        }
      },
      yAxis: {
        type: 'value',
        interval: 50,
        minInterval: 50,
        axisLabel: {
          // y 軸標籤
          formatter: function (value) {
            return value + '%'
          },
          color: '#535965',
          fontWeight: 600,
          fontSize: 14,
          padding: [0, 12, 0, 0]
        },
        splitLine: {
          // 水平分隔線
          lineStyle: {
            color: '#393D46'
          }
        }
      },
      series: [
        {
          name: '流量',
          data: mapYData(data[chartState.portType].yAxis[chartState.dir]), // 取得 chartState 對應 y 軸資料，(Array of Object)
          type: 'bar',
          barWidth: '87%',
          itemStyle: {
            // bar 樣式
            color: '#2F9471',
            borderRadius: 12
          },
          emphasis: {
            itemStyle: {
              color: '#63D5AB'
            }
          }
        }
      ]
    }

    // 返回 option
    return option
  }

  const draw = () => {
    const chartInstanse = echarts.init(chartElement.value)

    // 使用剛指定的配置和數據顯示圖表
    chartInstanse.setOption(optionWithState())

    return chartInstanse
  }
  return {
    chartElement,
    draw,
    optionWithState
  }
}

export default useEChartsInPortFlow
