
import { defineComponent, reactive, ref, markRaw } from 'vue'
import DropdownList from '@/components/Dropdown/DropdownList.vue'
import BaseButtonGroup from '@/components/Button/BaseButtonGroup.vue'
import BaseButtonGroupBtn from '@/components/Button/BaseButtonGroupBtn.vue'
import '@progress/kendo-ui/js/kendo.treeview'
import { TreeView } from '@progress/kendo-treeview-vue-wrapper'
import '@progress/kendo-ui/js/kendo.data'
// import { HierarchicalDataSource } from '@progress/kendo-datasource-vue-wrapper'
import { switchInfoTree, schema } from '@/mock/data/switchInfoTree'

export default defineComponent({
  components: {
    DropdownList,
    BaseButtonGroup,
    BaseButtonGroupBtn,
    TreeView
  },
  props: {
    title: String
  },
  setup() {
    // Switch 資訊顯示狀態
    const infoState = reactive({
      site: 0,
      type: 'device'
    })
    // 下拉相關
    const sites = ['全部區域', 'Taipei Office', 'San Jose Office']
    const dropdownChange = value => {
      infoState.site = sites.indexOf(value)
    }
    // 按鈕組相關
    const btnGroupType = ref([
      { id: 0, name: '裝置類型', value: 'device', active: true },
      { id: 1, name: '網段', value: 'network', active: false }
    ])
    const toggleBtnGroupType = id => {
      btnGroupType.value.forEach(btn => {
        if (btn.id == id) {
          btn.active = true
          infoState.type = btn.value
        } else {
          btn.active = false
        }
      })
    }

    // TreeView 相關
    // 注意！目前先使用 localDataSource 作法，待後端 API 完成請使用 remote HierarchicalDataSource

    /* template 目的為依據 item.icons 欄位渲染 icon，例如：<span class="tree-icon tree-i-site"></span>
     * .tree-i-* 須額外定義，檔案位置： '@/assets/styles/global/modify/kendo/_treeview.scss'
     */

    // 模版
    const template =
      '# for (i = 0; i < item.icons.length; i++ ) { #' +
      '<span class="tree-icon ' +
      '#: item.icons[i] #' +
      '"></span>' +
      '# } #' +
      '#: item.name #'

    // 資料源
    const localDataSource = markRaw(
      // eslint-disable-next-line
        new kendo.data.HierarchicalDataSource({
        data: switchInfoTree, // 假資料
        schema: schema
      })
    )

    return {
      infoState,
      sites,
      dropdownChange,
      btnGroupType,
      toggleBtnGroupType,
      template,
      localDataSource
    }
  }
})
