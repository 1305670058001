
import { defineComponent } from 'vue'
import TopEventsCard from '@/components/Card/TopEventsCard.vue'
import PortFlowPanel from '@/components/Panel/PortFlowPanel.vue'
import SwitchInfoPanel from '@/components/Panel/SwitchInfoPanel.vue'
import { Dialog } from '@progress/kendo-vue-dialogs'
import useToggle from '@/composable/useToggle'
import PortInfo from '@/views/Switch/PortInfo.vue'

export default defineComponent({
  components: {
    TopEventsCard,
    PortFlowPanel,
    SwitchInfoPanel,
    Dialog,
    PortInfofoDialog: PortInfo
  },
  setup() {
    const topEvents = [
      {
        id: 0,
        title: 'Port 流量超過 80 %',
        count: 2,
        importance: 'high', // or "normal" (重要程度)
        href: ''
      },
      {
        id: 1,
        title: 'Switch 未回應',
        count: 2,
        importance: 'high',
        href: ''
      },
      {
        id: 2,
        title: '連接多台 MAC 的 Port',
        count: 0,
        importance: 'normal',
        href: ''
      }
    ]

    const {
      show: showPortInfoDialog,
      toggle: togglePortInfoDialog
    } = useToggle()
    return {
      topEvents,
      showPortInfoDialog,
      togglePortInfoDialog
    }
  }
})
