<template>
  <div id="page-switch-dashboard">
    <div class="card-container">
      <!-- 上方紅、綠卡牌 -->
      <TopEventsCard
        v-for="event in topEvents"
        :key="event.id"
        :event="event"
      />
    </div>
    <div class="panel-container">
      <PortFlowPanel
        :title="'前 10 筆 Switch Port 流量'"
        @toggleSwitchInfoDialog="togglePortInfoDialog"
      />
      <SwitchInfoPanel :title="'Switch 資訊'" />
    </div>

    <!-- 隱藏：Dialog 容器 -->
    <div class="dialog-container main-list-view" style="height: 0; padding: 0;">
      <Dialog
        class="full-dialog"
        v-if="showPortInfoDialog"
        :title="'Port 使用狀況'"
        @close="togglePortInfoDialog"
      >
        <PortInfofoDialog @close="togglePortInfoDialog" />
      </Dialog>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import TopEventsCard from '@/components/Card/TopEventsCard.vue'
import PortFlowPanel from '@/components/Panel/PortFlowPanel.vue'
import SwitchInfoPanel from '@/components/Panel/SwitchInfoPanel.vue'
import { Dialog } from '@progress/kendo-vue-dialogs'
import useToggle from '@/composable/useToggle'
import PortInfo from '@/views/Switch/PortInfo.vue'

export default defineComponent({
  components: {
    TopEventsCard,
    PortFlowPanel,
    SwitchInfoPanel,
    Dialog,
    PortInfofoDialog: PortInfo
  },
  setup() {
    const topEvents = [
      {
        id: 0,
        title: 'Port 流量超過 80 %',
        count: 2,
        importance: 'high', // or "normal" (重要程度)
        href: ''
      },
      {
        id: 1,
        title: 'Switch 未回應',
        count: 2,
        importance: 'high',
        href: ''
      },
      {
        id: 2,
        title: '連接多台 MAC 的 Port',
        count: 0,
        importance: 'normal',
        href: ''
      }
    ]

    const {
      show: showPortInfoDialog,
      toggle: togglePortInfoDialog
    } = useToggle()
    return {
      topEvents,
      showPortInfoDialog,
      togglePortInfoDialog
    }
  }
})
</script>

<style scoped></style>
